import React from "react";

export default function(props) {
  let style = Object.assign({
    position: "absolute",
    width: "100%",
    height: `100%`,
    boxSizing: "border-box",
    border: props.debug ? "1px solid red" : null,
    zIndex: 0,
  }, (() => {
    switch (props.magnifyDirection) {
      case "up": return { left: 0, };
      case "down": return { right: 0, };
      case "center": return { left: "50%", transform: "translateX(-50%)", };
    }
  })());

  return <div className={props.className} style={style} />;
}

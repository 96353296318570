import React from "react";

export default function(props) {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
      style={{
        width: `3vw`,
        height: `${props.width}px`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        border: props.debug ? "1px solid red" : null,
        zIndex: 1
        // fontSize: props.width/2
      }}
    >
      {props.children}
    </div>
  );
}

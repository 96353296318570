const projects = {
  projects: [
    {
      title: "Khan Academy",
      view: "wide",
      date: "2019",
      color: "#A4D68D",
      description:
        "Bringing Khan Academy’s world-class math content to mobile with a set of fluid, interactive exercises.",
      collaboration: "Nefaur Khandker",
      link: "https://itunes.apple.com/us/app/khan-academy/id469863705",
      role: "User Interface Design",
      tiles: [
        {
          src: "ka-rotate",
          type: "video",
          frame: "iphone-se",
          title: "Graphing",
          desc:
            "Explore transformations by manipulating them directly on the graph.",
          color: "#A4D68D"
        },
        {
          src: "ka-formal",
          type: "video",
          frame: "iphone-se",
          title: "Graphing",
          desc: "Create and edit complex multi-step transformations."
        },
        {
          src: "ka-graph",
          type: "video",
          frame: "iphone-se",
          title: "Graphing",
          desc:
            "Graph different types of equations by manipulating them directly on the graph."
        },
        {
          src: "ka-choice", //this will be multiple choice
          type: "video",
          frame: "iphone-se",
          title: "Graphing",
          desc:
            "Answer multiple-choice questions and recieve informational feedback."
        },
      {
          src: "wonder-blocks.png", //this will be multiple choice
          type: "image",
          frame: "web",
          title: "Graphing",
          desc:
            "Khan Academy's preliminary deisgn system, Wonder Blocks."
        }
      ]
    },
    {
      title: "Cancerbase",
      view: "wide",
      date: "2018",
      role: "User Interface Design",
      color: "#607DE3",

      description:
        "A thoughtful tool to help cancer patients ask questions and find trustworthy answers about their disease.",
      collaboration: "Parker Malachowsky and Sara Ma",
      tiles: [
        {
          src: "cb-sign-up",
          type: "video",
          frame: "iphone-x",
          title: "",
          desc: "Search for your cancer and connect with your community."
          //color: "#607DE3"
        },
        {
          src: "cb-build-profile",
          type: "video",
          frame: "iphone-x",
          desc: "Sign up and build your profile through a guided onboarding flow."
        }
      ]
    },
    // {
    //   title: "Not So Linear",
    //   view: "wide",
    //   description:
    //     "An experimental interface design project to help doctors make sense and use of neural networks.",
    //   collaboration: "Parker Malachowsky",
    //   link: "http://notsolinear.blog",
    //   tiles: [
    //     {
    //       src: "moth-cover.png",
    //       type: "cover",
    //       frame: "web",
    //       color: "#333"
    //     }
    //   ]
    // },

    {
      title: "Manufactur",
      view: "wide",
      date: "2016",
      description:
        "Various websites for clients while at Manufactur, a creative agency based in Downtown Los Angeles.",
      collaboration: "Matthew Bowers",
      role: "Web Design",

      tiles: [
        {
          src: "cedric",
          type: "video",
          frame: "web",
          title: "Cedric Gervais",
          desc: "A website for French DJ and producer Cedric Gervais."
        },
        {
          src: "hpb",
          type: "video",
          frame: "web",
          title: "Highland Park Bowl",
          desc:
            "A website for the historic bowling alley in Highland Park, Los Angeles."
        },
        {
          src: "cape",
          type: "video",
          frame: "web",
          title: "Cape Cod",
          desc: "An interactive travel guide to Cape Cod."
        }
      ]
    },
    {
      title: "Moth",
      date: "2017",
      view: "wide",
      description: "An online face for a unique Japanese and Nordic boutique.",
      role: "Web Design + Development",
      link: "http://mothchicago.com",
      tiles: [
        {
          src: "moth-home",
          type: "video",
          frame: "web",
          title: "",
          desc: "Explore the interior and browse new arrivals on the home page."
        },
        {
          src: "moth-product",
          type: "video",
          frame: "web",
          title: "",
          desc:
            "Explore product photography and related products on the product page."
        }
      ]
    },
    {
      title: "Tides",
      view: "wide",
      date: "2013",
      description: "A friendly tide-tracking app built for beachgoers.",
      role: "User Interface Design",

      tiles: [
        {
          src: "tides",
          type: "video",
          frame: "iphone-se",
          title: "",
          desc:
            "Check the tide level by scrubbing through time and looking through the virtual porthole."
        }
      ]
    },
    {
      title: "Flights",
      view: "wide",
      date: "2013",
      description: "A live flight-tracking dashboard.",
      role: "User Interface Design",

      tiles: [
        {
          src: "flights",
          type: "video",
          frame: "iphone-4s",
          title: "",
          desc: "Check your flight's status at-a-glance."
        }
      ]
    },
    {
      title: "macOS Icons",
      view: "wide",
      date: "2016",
      description:
        "Various icons I designed at the start of my career, mostly in Photoshop.",
      tiles: [
        {
          src: "selected-macos.png",
          type: "image",
          frame: "square",
          desc: "macOS icons designed for clients and for practice."
        }
      ]
    },
    {
      title: "Branding",
      view: "wide",
      date: "2016",
      description: "Various projects involving custom graphics, photography, and type.",

      tiles: [
        {
          src: "bike-spread.png",
          type: "image",
          frame: "square",
          title: "",

          desc: "A concept magazine spread for a new kind of bike."
        },
        {
          src: "compendium.png",
          type: "image",
          frame: "square",
          title: "",

          desc: "An album cover for the indie folk band, Bannack."
        },
        {
          src: "brochure.png",
          type: "image",
          frame: "square",
          title: "",
          desc:
            "A brochure for an architectural research project in Los Angeles."
        },
        {
          src: "reverb-posters.png",
          type: "image",
          frame: "square",
          desc: "Alternate logos for the online marketplace, Reverb."
        }
      ]
    }
  ]
};
export default projects;
